<template>
  <el-main>
    <el-form ref="form" :model="form" label-width="200px" :rules="rules">
      <el-form-item label="申请区域分红条件:" prop="condition">
        <el-input v-model="form.condition" placeholder="邀请员工数量满足个数"></el-input>个
      </el-form-item>
      <el-form-item label="区域分红奖励比例:" prop="ratio">
        <el-input v-model="form.ratio" placeholder="占商品积分的比例"></el-input>%
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation'
export default {
  components: {
    Preservation
  },
  data() {
    const checkCondition = (rules, value, callback) => {
      let reg = /^[0-9]*$/
      if (this.form.condition.length > 8) {
        callback('十位数以内')
      } else if (!reg.test(Number(this.form.condition))) {
        callback('请正确填写申请区域分红条件')
      } else {
        callback()
      }
    }
    const checkRatio = (rules, value, callback) => {
      let reg = /^[0-9]*$/
      if (this.form.ratio.length > 8) {
        callback('十位数以内')
      } else if (!reg.test(Number(this.form.ratio))) {
        callback('请填写区域分红奖励比例')
      } else {
        callback()
      }
    }

    return {
      form: {
        condition: null,
        ratio: null
      },
      rules: {
        condition: [{ validator: checkCondition, trigger: 'blur' }],
        ratio: [{ validator: checkRatio, trigger: 'blur' }]
      }
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.$axios.post(this.$api.push.regionalShareholders.getSet).then((res) => {
        if (res.code == 0) {
          this.form.condition = res.result.condition
          this.form.ratio = res.result.ratio
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    preservation() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.push.regionalShareholders.add, this.form).then((res) => {
            if (res.code == 0) {
              this.$message.success('设置成功')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  .el-form {
    .el-input {
      width: 400px;
    }
  }
}
</style>
